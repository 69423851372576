const initialState = {
  sinistres: [],
};

function sinistresReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_SINISTRES":
      return {
        ...state,
        sinistres: [...action.data],
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}

export default sinistresReducer;
