import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    tableTd: {
      width: "100%",
      "& .MuiBox-root .MuiTable-root .MuiTableBody-root .MuiTableCell-root, & .MuiBox-root .MuiTable-root .MuiTableHead-root .MuiTableCell-root":
        {
          fontFamily: "Poppins-Regular !important",
          fontWeight: "400 !important",
          textWrap: "nowrap",
          borderBottom: "1px solid #0898ff",
          "& .MuiTableSortLabel-root": {
            fontFamily: "Poppins-ExtraBold",
            fontSize: 16,
            color: "#000000",
          },
        },

      "& .MuiTableFooter-root .MuiTableCell-root": {
        border: "none",
      },
      "& .MuiPaper-root > .MuiToolbar-root": {
        borderBottom: `1px solid #0898ff`,
      },
    },
    sinistreParagraph: {
      fontFamily: "Poppins-Regular !important",
      fontWeight: "400 !important",
      textWrap: "nowrap",
    },
    searchButton: {
      width: 300,
      "& .MuiInputBase-root": {
        paddingRight: "6px !important",
      },
    },
  })
);
