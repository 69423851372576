export const NORMAL = 15
export const FONTSEMIBIG = 20
export const LITTLE = 12
export const LITTLE2 = 14
export const MICRO = 10
export const MICROPLUS = 8
export const BIG=25
export const MEGABIG=30
export const NORMALSUP = 16
export const MINIBIG = 18
