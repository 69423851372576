import React from "react";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";

// CodeNekt imports
import Logo from "../../assets/png/logoPng.png";
import CodenektButton from "../../Components/CodeNektButton";
import {
  CodeNektEye,
  CodeNektEyeBlocked,
} from "../../Components/CodeNektIcons";
import { login } from "../../api/modules/User";
import { checkEmail, checkPassword } from "../../utils/checkForms";
import { useStyles } from "./styles";
import { Button } from "@mui/base";
import { useNavigate } from "react-router-dom";
const ITEMWIDTH = "80%";

const LoginInput = (props) => {
  const classes = useStyles();
  const [viewPassword, setViewPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setViewPassword(!viewPassword);
  };

  return (
    <TextField
      className={classes.textfield}
      placeholder={props.label}
      onChange={props.onChange}
      type={props.pass ? (viewPassword ? "text" : "password") : "text"}
      size="small"
      variant="outlined"
      value={props.value}
      InputLabelProps={{ shrink: false }}
      InputProps={
        props.pass && {
          endAdornment: (
            <IconButton onClick={handleClickShowPassword}>
              {!viewPassword ? (
                <CodeNektEye color={"#ffffff"} size={8} />
              ) : (
                <CodeNektEyeBlocked color={"#ffffff"} size={8} />
              )}
            </IconButton>
          ),
        }
      }
    />
  );
};

const ConnectButton = (props) => {
  const classes = useStyles();

  return (
    <Button className={classes.customButton} onClick={props.onClick}>
      Se connecter
    </Button>
    // <CodenektButton
    //   white
    //   onClick={props.onClick}
    //   title="Se connecter"
    //   width={ITEMWIDTH}
    // />
  );
};

const LoginForm = (props) => {
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(false);
  const user = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const Checklogin = async (user) => {
    const response = await login(user)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: "LOGIN", data: response.data });

          navigate("/");
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  };

  const InputValidate = () => {
    if (!checkEmail(email)) {
      setError(true);
    } else if (!checkPassword(password)) {
      setError(true);
    } else {
      Checklogin({ email: email, password: password });
    }
  };

  return (
    <div
      style={{
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: 350,
      }}
    >
      <div className={classes.texfieldContainer}>
        <LoginInput label="Email" onChange={handleEmailChange} value={email} />
        <LoginInput
          label="Mot de passe"
          pass={true}
          onChange={handlePasswordChange}
          value={password}
        />
      </div>

      <FormControlLabel
        control={
          <Checkbox color="primary" className={classes.customCheckbox} />
        }
        className={classes.customLabel}
        label="Se souvenir de moi"
      />
      <ConnectButton onClick={InputValidate} />
      <div className={classes.forgotPassword}>Mot de passe oublié</div>
      {error && (
        <p style={{ color: "white" }}>Email ou mot de passe incorrect</p>
      )}
    </div>
  );
};

const Login = (props) => {
  return (
    <div
      style={{
        alignContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "auto",
        textAlign: "center",
        width: "100%",
        background: "#0898FF",
        height: "100vh",
      }}
    >
      <div className="CodeNekt__logo">
        <img src={Logo} alt="" style={{ width: "12rem", marginBottom: 20 }} />
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
