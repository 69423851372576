import { combineReducers } from "redux"; // Imports: Reducers
import brandsReducer from "./brandsReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";
import collaborateursReducer from "./collaborateursReducer";
import sinistresReducer from "./sinistresReducer";

// Redux: Root Reducer
const rootReducer = combineReducers({
  brandsReducer: brandsReducer,
  userReducer: userReducer,
  notificationReducer: notificationReducer,
  collaborateursReducer: collaborateursReducer,
  sinistresReducer: sinistresReducer,
});

export default rootReducer;
