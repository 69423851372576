import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStylesVehicule = makeStyles((theme) =>
  createStyles({
    typography: {
      fontFamily: "Poppins-Black",
      fontSize: "24px",
      color: "#000000",
      margin: "21px 0px 8px 35px",
    },
    chipNumber: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: "0.5px solid rgb(255, 111, 8)",
      fontFamily: "Poppins-Black",
      fontSize: "30px",
      margin: "28px 0px 12px",
      borderRadius: "50px",
      width: "148px",
      height: "45px",
    },
    divider: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      lineHeight: 0,
    },
    button: {
      fontFamily: "Poppins-ExtraBold",
      color: "white",
      fontSize: "14px",
      backgroundColor: "#0898FF",
      borderRadius: "50px",
    },
  })
);
