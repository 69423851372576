import React from "react";
import { Grid } from "@mui/material";

// CodeNekt imports
import CodeNektSearch from "../../Components/CodeNektSearch";
import CodenektButton from "../../Components/CodeNektButton";
import { WHITECN } from "../../utils/colors";
import { CodeNektAdd } from "../../Components/CodeNektIcons";
import {
  LinkAjoutCollab,
  LinkAjoutSinistre,
  LinkAjoutVehicule,
} from "../../Components/CodeNektPageLinks";
import { useStyles } from "./NotificationStyle";

const DashboardSearchButton = (props) => {
  return (
    <CodeNektSearch
      height={"1rem"}
      label={"Rechercher nom collaborateur par exemple"}
      searchData={props.data}
      width={"100%"}
    />
  );
};

const DashboardHeaderButton = (props) => {
  return (
    <CodenektButton
      blue
      bold
      borderRadius={50}
      color={WHITECN}
      height={"2rem"}
      margin={"auto"}
      paddingLeft={28}
      paddingRight={28}
      startIcon={props.startIcon}
      title={props.title}
      to={props.to}
      width={"fit-content"}
    />
  );
};

const HeaderButtons = (props) => {
  const classes = useStyles();
  const data = [
    { label: "Véhicule 1" },
    { label: "Véhicule 2" },
    { label: "Véhicule 3" },
    { label: "Véhicule 4" },
    { label: "Véhicule 5" },
    { label: "Véhicule 3" },
    { label: "Véhicule 4" },
    { label: "Véhicule 5" },
    { label: "Véhicule 3" },
    { label: "Véhicule 4" },
    { label: "Véhicule 5" },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "24px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "24px",
        }}
      >
        <DashboardHeaderButton
          startIcon={<CodeNektAdd fontSize="small" color="white" />}
          title={"AJOUTER UN COLLABORATEUR"}
          to={LinkAjoutCollab}
        />
        <DashboardHeaderButton
          startIcon={<CodeNektAdd fontSize="small" color="white" />}
          title={"AJOUTER UN VÉHICULE"}
          to={LinkAjoutVehicule}
        />
        <DashboardHeaderButton
          startIcon={<CodeNektAdd fontSize="small" color="white" />}
          title={"DECLARER UN SINISTRE"}
          to={LinkAjoutSinistre}
        />
      </div>
      <div className={classes.searchButton}>
        <DashboardSearchButton data={data} />
      </div>
    </div>
  );
  /*
   <Grid item xs={12} sm={12} md={12} sx={{ margin: "1rem 0 2rem 0" }}>
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} sm={3} md={3}>
          <DashboardHeaderButton
            startIcon={<CodeNektAdd fontSize="small" color="white" />}
            title={"AJOUTER UN COLLABORATEUR"}
            to={LinkAjoutCollab}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2.5}>
          <DashboardHeaderButton
            startIcon={<CodeNektAdd fontSize="small" color="white" />}
            title={"AJOUTER UN VÉHICULE"}
            to={LinkAjoutVehicule}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2.5}>
          <DashboardHeaderButton
            startIcon={<CodeNektAdd fontSize="small" color="white" />}
            title={"DECLARER UN SINISTRE"}
            to={LinkAjoutSinistre}
          />
        </Grid>
        <Grid className={classes.searchButton} item xs={12} sm={3} md={4}>
          <DashboardSearchButton data={data} />
        </Grid>
      </Grid>
    </Grid>
  */
};

export default HeaderButtons;
