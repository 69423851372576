import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    texfieldContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 12,
    },
    textfield: {
      "& .MuiInputBase-input": {
        fontSize: 10,
        fontFamily: "Poppins-Regular",
        color: "white",
        padding: "6px 25px",
      },
      "& .MuiInputBase-root": {
        border: "2px solid white",
        borderRadius: 21,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
      },
    },
    customButton: {
      width: "100%",
      height: "31px",
      borderRadius: "50px",
      color: "#0898FF",
      fontFamily: "Poppins-Bold",
      fontSize: "14px",
      margin: "0",
      backgroundColor: "white",
      border: 0,
    },
    forgotPassword: {
      fontFamily: "Poppins-Regular",
      fontSize: 8,
      textDecoration: "underline",
      marginTop: 10,
    },
    customLabel: {
      marginLeft: "4px !important",
      marginBottom: 10,
      marginTop: 10,
      "& .MuiTypography-root": {
        color: "white",
        fontFamily: "Poppins-Light",
        fontSize: 12,
      },
    },
    customCheckbox: {
      "& .MuiSvgIcon-root": {
        fontSize: 12,
        color: "white",
      },
    },
  })
);
