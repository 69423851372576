export const categories = [
    {
        label: "Catégorie 1",
        value: 1,
    },
    {
        label: "Catégorie 2",
        value: 2,
    },
    {
        label: "Catégorie 3",
        value: 3,
    },
    {
        label: "Catégorie 4",
        value: 4,
    },
];