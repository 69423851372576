import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BLACKCN, BLUECN } from "../../utils/colors.js";
import { IconButton, Menu, MenuItem, MenuList } from "@mui/material";
import CodeNektTable from "../../Components/CodeNektTable.js";
import {
  CodeNektBellFill,
  CodeNektEye,
  CodeNektThreeDots,
} from "../../Components/CodeNektIcons.js";
import { getNotifications } from "../../api/modules/Notifications";
import moment from "moment";

const NotificationTableFontsize = "12px";
const NotificationTableHeadersize = "10px";

const NotificationDropdownActions = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const MenuOptions = [
    "Marquer comme lu",
    "Relancer le collaborateur",
    "Supprimer",
  ];

  return (
    <>
      <IconButton onClick={handleClick}>
        <CodeNektThreeDots size={15} />{" "}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ paddingTop: 0 }}
        MenuListProps={{ disablePadding: true }}
      >
        <MenuList sx={{ padding: 0, margin: 0 }}>
          {MenuOptions.map((option) => (
            <MenuItem
              key={option}
              onClick={handleClose}
              sx={{
                fontSize: NotificationTableFontsize,
                color: BLACKCN,
                paddingTop: 0,
                "&:hover": {
                  color: BLUECN,
                },
                "&:hover::before": {
                  content: '""',
                  display: "inline-block",
                  marginRight: "0.2rem",
                  color: BLUECN,
                },
              }}
            >
              {option}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

// change column fontsize in the cellStyle
const NotificationColumns = {
  names: [
    {
      title: "",
      sorting: false,
      filtering: false,
      search: false,
      render: () => {
        return <CodeNektBellFill size={12} color={"red"} />;
      },
    },
    {
      title: "Date",
      field: "Date",
      cellStyle: { fontSize: NotificationTableFontsize },
      headerStyle: { fontSize: NotificationTableHeadersize },
      render: (data) => {
        return <>{moment(data.date).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: "Nom",
      field: "data.name",
      cellStyle: { fontSize: NotificationTableFontsize },
      headerStyle: { fontSize: NotificationTableHeadersize },
    },
    {
      title: "Type d'Intervention",
      field: "type",
      cellStyle: { fontSize: NotificationTableFontsize },
      headerStyle: { fontSize: NotificationTableHeadersize },
    },
    {
      title: "Statut",
      field: "Statut",
      cellStyle: { fontSize: NotificationTableFontsize },
      headerStyle: { fontSize: NotificationTableHeadersize },
      render: (data) => {
        return <>{data.is_read === 1 ? "Lu" : "Non lu"}</>;
      },
    },
    {
      title: "Points",
      field: "data.points",
      cellStyle: { fontSize: NotificationTableFontsize },
      headerStyle: { fontSize: NotificationTableHeadersize },
    },
    {
      title: "Marque",
      field: "",
      cellStyle: { fontSize: NotificationTableFontsize },
      headerStyle: { fontSize: NotificationTableHeadersize },
    },
    {
      title: "Collaborateur",
      field: "Collaborateur",
      cellStyle: { fontSize: NotificationTableFontsize },
      headerStyle: { fontSize: NotificationTableHeadersize },
    },
    {
      title: "",
      field: "actions",
      sorting: false,
      filtering: false,
      search: false,
      render: (rowData) => {
        return (
          <div style={{ display: "flex" }}>
            <IconButton>
              <CodeNektEye size={15} />{" "}
            </IconButton>
            <NotificationDropdownActions />
          </div>
        );
      },
    },
  ],
};

const NotificationTable = (props) => {
  useEffect(() => {
    getNotificationsList();
  }, []);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);

  const getNotificationsList = async () => {
    try {
      const responseRead = await getNotifications(user.token, true);
      const responseNotRead = await getNotifications(user.token, false);

      if (responseRead.status === 200 || responseNotRead.status === 200) {
        const notificationsListRead = responseRead.data;
        const notificationsListNotRead = responseNotRead.data;
        const allNotifications = [
          ...notificationsListRead,
          ...notificationsListNotRead,
        ].map((notif) => {
          return { ...notif, data: JSON.parse(notif.data) };
        });
        dispatch({ type: "LIST_NOTIFICATIONS", data: allNotifications });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const notifications = useSelector(
    (state) => state.notificationReducer.notifications
  );

  return (
    <CodeNektTable
      column={NotificationColumns}
      data={notifications}
      header={"NOTIFICATIONS"}
      rowsPerPage={5}
      padding={30}
      selection={false}
      toolbar={false}
    />
  );
};

export default NotificationTable;
