import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import CodeNektSidePanel from "./CodeNektSidePanel";

export const PrivateRoute = ({ children }) => {
  const isAuthenticated = useSelector(
    (state) => state.userReducer.isAuthenticated
  );
  if (!isAuthenticated) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return (
    <div className="Panel" style={{ display: "flex" }}>
      <CodeNektSidePanel />
      <div style={{ width: "100%", overflowX: "hidden" }}>{children}</div>
    </div>
  );
};

export default PrivateRoute;
