export const BLACKCN = '#312B27'
export const BLUECN = '#0898FF'
export const BORDERCN = '#707070'
export const BORDEROPACITY='rgba(112, 112, 112, 0.16)'
export const GREENLIGHT = '#00FF00'
export const GREENDARK = '#008000'
export const GREENCN = '#00B050'
export const GREYCN = '#4F4F4F'
export const GREYTEXT = '#A8A8A8'
export const GREYBACK = '#EFEFEF'
export const GREYTEXT2 = '#BFBFBF'
export const MAINCOCOON = '#3399FF'
export const ORANGE = '#F36A23'
export const ORANGEDARK = '#FF0000'
export const ORANGELIGHT = '#FF6F08'
export const ORANGEDULL = 'rgba(243, 106, 35, 0.16)'
export const WHITECN = '#FFFFFF'