import React from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";

// ----------------- Pages -----------------
import CodeNektSidePanel from "../Components/CodeNektSidePanel";
import Login from "./Login/Login";
import Dashboard from "./Dashboard/Dashboard";
import Collaborator from "./Collaborateur/Collaborateur";
import Vehicles from "./Vehicule/Vehicles";
import Notifications from "../pages/Notifications";
import Claims from "./Sinistre/Claims";
import Cartes from "./Carte/Cartes";
import TCO from "./TCO/TCO";
import Services from "../pages/Services";
import Settings from "./Settings/Settings";
import Signout from "./Login/Signout";

// ----------------- Voir Pages -----------------
import VoirCollaborateur from "./Collaborateur/VoirCollaborateur";
import VoirSinistre from "./Sinistre/VoirSinistre";
import VoirVehicule from "./Vehicule/VoirVehicule";
import VoirCarte from "./Carte/VoirCarte";

// ----------------- Ajouter -----------------
import AjouterCollaborateur from "./Ajouter/Collaborateur/AjouterCollaborateur";
import AjouterVehicule from "./Ajouter/Vehicule/AjouterVehicule";
import AjouterSinistre from "./Ajouter/Sinistre/AjouterSinistre";
import AjouterCarte from "./Ajouter/Carte/AjouterCarte";

import {
  LinkSinistre,
  LinkVehicule,
  LinkCollaborateur,
  LinkDashboard,
  LinkNotifications,
  LinkCartes,
  LinkTCO,
  LinkServices,
  LinkSettings,
  LinkSignout,
  LinkAjoutCollab,
  LinkAjoutVehicule,
  LinkAjoutSinistre,
  LinkAjoutCarte,
  LinkVoirCollab,
  LinkVoirVehicule,
  LinkVoirSinistre,
  LinkVoirCarte,
  LinkRetranscription,
} from "../Components/CodeNektPageLinks.js";
import Retranscription from "./Retranscription/Retranscription";
import PrivateRoute from "../Components/PrivateRoute";

const NavigationView = () => {
  return (
    <main style={{ position: "relative" }}>
      <Routes>
        <Route
          exact
          path={LinkDashboard}
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={LinkCollaborateur}
          element={
            <PrivateRoute>
              <Collaborator />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkAjoutCollab}
          element={
            <PrivateRoute>
              <AjouterCollaborateur />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkVoirCollab}
          element={
            <PrivateRoute>
              <VoirCollaborateur />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={LinkVehicule}
          element={
            <PrivateRoute>
              <Vehicles />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkAjoutVehicule}
          element={
            <PrivateRoute>
              <AjouterVehicule />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkVoirVehicule}
          element={
            <PrivateRoute>
              <VoirVehicule />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={LinkSinistre}
          element={
            <PrivateRoute>
              <Claims />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkAjoutSinistre}
          element={
            <PrivateRoute>
              <AjouterSinistre />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkVoirSinistre}
          element={
            <PrivateRoute>
              <VoirSinistre />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={LinkNotifications}
          element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={LinkCartes}
          element={
            <PrivateRoute>
              <Cartes />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkAjoutCarte}
          element={
            <PrivateRoute>
              <AjouterCarte />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkVoirCarte}
          element={
            <PrivateRoute>
              <VoirCarte />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path={LinkTCO}
          element={
            <PrivateRoute>
              <TCO />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkServices}
          element={
            <PrivateRoute>
              <Services />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkSettings}
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={LinkSignout}
          element={
            <PrivateRoute>
              <Signout />
            </PrivateRoute>
          }
        />
        <Route exact path={"/login"} element={<Login />} />
        <Route exact path={LinkRetranscription} element={<Retranscription />} />
      </Routes>
    </main>
  );
};

function Navigation(props) {
  return <NavigationView />;
}

const mapStateToProps = (state) => {
  return {
    userReducer: state.userReducer,
  };
};

export default connect(mapStateToProps)(Navigation);
