import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    vehiculesTable: {
      "& .MuiTextField-root": {
        width: "26%",
        backgroundColor: "#F2F2F2",
        borderRadius: "50px",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiOutlinedInput-root": {
          height: "31px",
          fontFamily: "Poppins-Regular",
          fontSize: "10px",
        },
        "& .MuiIconButton-root": {
          display: "none",
        },
        "& .MuiSvgIcon-fontSizeSmall": {
          position: "absolute",
          right: "12px",
        },
        "& .MuiOutlinedInput-input": {
          position: "absolute",
        },
      },
      "& .MuiTableCell-head": {
        borderBottom: "1px solid #0898ff",
      },
      "& .MuiTableHead-root .MuiTableCell-root .MuiTableSortLabel-root, & .MuiTableHead-root .MuiTableCell-root > div":
        {
          fontFamily: "Poppins-Bold",
          fontSize: "14px",
          color: "#000000",
        },
      "& .MuiTableBody-root": {
        fontFamily: "Poppins-Regular",
        fontSize: "12px",
        textWrap: "nowrap",
        "& .MuiTableCell-body": {
          borderBottom: "1px solid #0898ff",
        },
      },
      "& .MuiToolbar-gutters": {
        padding: 0,
      },
      "& .MuiTableBody-root .MuiIconButton-root": {
        backgroundColor: "#0898FF1A",
        marginRight: 14,
      },
    },
  })
);
