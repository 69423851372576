const notifications = [
    {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
   },
   {
        Date: "2020-01-01",
        Type: "Cirthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
    },
    {
        Date: "2020-01-01",
        Type: "Dirthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
   },
   {
        Date: "2020-01-01",
        Type: "Eirthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
    },
    {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
   },
   {
        Date: "2020-01-01",
        Type: "Firthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
    },
    {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
   },
   {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
    },    {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
   },
   {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
    },
    {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
   },
   {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
    },    {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
   },
   {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
    },
    {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
   },
   {
        Date: "2020-01-01",
        Type: "Birthday",
        XX: "XX",
        Marque: "Marque",
        Collaborateur: "Collaborateur",
    },
]

const collaborator = [
    {
        Cat: "Cat",
        Nom: "Nom",
        Prenom: "Prenom",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom1",
        Prenom: "Prenom1",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom2",
        Prenom: "Prenom2",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom3",
        Prenom: "Prenom3",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom",
        Prenom: "Prenom",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom1",
        Prenom: "Prenom1",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom2",
        Prenom: "Prenom2",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom3",
        Prenom: "Prenom3",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom",
        Prenom: "Prenom",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom1",
        Prenom: "Prenom1",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom2",
        Prenom: "Prenom2",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom3",
        Prenom: "Prenom3",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom",
        Prenom: "Prenom",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom1",
        Prenom: "Prenom1",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom2",
        Prenom: "Prenom2",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },
    {
        Cat: "Cat",
        Nom: "Nom3",
        Prenom: "Prenom3",
        Vehicle: "Vehicle",
        Marque: "Marque",
    },

]

const vehicles = [
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele",
        Cat: "Cat",
        Collaborateur: "Collaborateur",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele1",
        Cat: "Cat",
        Collaborateur: "Collaborateur1",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele",
        Cat: "Cat",
        Collaborateur: "Collaborateur",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele1",
        Cat: "Cat",
        Collaborateur: "Collaborateur1",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele",
        Cat: "Cat",
        Collaborateur: "Collaborateur",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele1",
        Cat: "Cat",
        Collaborateur: "Collaborateur1",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele",
        Cat: "Cat",
        Collaborateur: "Collaborateur",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele1",
        Cat: "Cat",
        Collaborateur: "Collaborateur1",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele",
        Cat: "Cat",
        Collaborateur: "Collaborateur",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele1",
        Cat: "Cat",
        Collaborateur: "Collaborateur1",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele",
        Cat: "Cat",
        Collaborateur: "Collaborateur",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
    {
        Immat: "Immat",
        Marque: "Marque",
        Modele: "Modele1",
        Cat: "Cat",
        Collaborateur: "Collaborateur1",
        Entree: "Entrée",
        Sortie: "Sortie",
        Statut: "Statut",
    },
]

const sinistres = [
    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },    {
        Date: "Date",
        Vehicle: "Vehicle",
        Nom: "Nom",
        Statut: "Statut",
    },
    {
        Date: "Date",
        Vehicle: "Vehicle1",
        Nom: "Nom1",
        Statut: "Statut",
    },
]

const cartes = [
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "No",
        Collaborateur: "Collaborateur",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "N1",
        Collaborateur: "Collaborateur1",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "No",
        Collaborateur: "Collaborateur",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "N1",
        Collaborateur: "Collaborateur1",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "No",
        Collaborateur: "Collaborateur",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "N1",
        Collaborateur: "Collaborateur1",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "No",
        Collaborateur: "Collaborateur",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "N1",
        Collaborateur: "Collaborateur1",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "No",
        Collaborateur: "Collaborateur",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "N1",
        Collaborateur: "Collaborateur1",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "No",
        Collaborateur: "Collaborateur",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "N1",
        Collaborateur: "Collaborateur1",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "No",
        Collaborateur: "Collaborateur",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "N1",
        Collaborateur: "Collaborateur1",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "No",
        Collaborateur: "Collaborateur",
    },
    {
        Type: "Type",
        Marque: "Marque",
        Usage: "Usage",
        No: "N1",
        Collaborateur: "Collaborateur1",
    },
]

export { notifications, collaborator, vehicles, sinistres, cartes };