import React from "react";
import { Grid } from "@mui/material";

// Local imports
import { sinistres as data } from "../utils/test-data";
import CodeNektTable from "../../Components/CodeNektTable";
import CodenektButton from "../../Components/CodeNektButton";
import { ORANGEDARK, ORANGELIGHT, WHITECN } from "../../utils/colors";
import { CodeNektEye, CodeNektAdd } from "../../Components/CodeNektIcons";
import {
  LinkAjoutSinistre,
  LinkVoirSinistre,
} from "../../Components/CodeNektPageLinks";
import { useStyles } from "./styles";

const column = {
  names: [
    { title: "Date".toLocaleUpperCase(), field: "Date", width: 100 },
    { title: "Vehicle".toLocaleUpperCase(), field: "Vehicle", width: 100 },
    { title: "Nom du Sinistre".toLocaleUpperCase(), field: "Nom", width: 200 },
    { title: "Statut".toLocaleUpperCase(), field: "Statut", width: 120 },
    {
      title: "",
      field: "actions",
      width: 480,
      sorting: false,
      filtering: false,
      search: false,
      cellStyle: { textAlign: "right" },
      render: (rowData) => {
        return (
          <>
            <CodenektButton
              dir={"to right"}
              fontFamily={"Poppins-ExtraBold"}
              fontSize={"13px"}
              color={"white"}
              blue
              padding={"14px"}
              paddingLeft={"22px"}
              paddingRight={"22px"}
              borderRadius={"50px"}
              onPress={() => console.log("Button pressed")}
              startIcon={<CodeNektEye size={16} />}
              title={"VOIR LE SINISTRE"}
              to={LinkVoirSinistre}
            />
          </>
        );
      },
    },
  ],
};

const Claims = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        margin: "32px 60px 60px 60px",
      }}
    >
      <Grid
        container
        spacing={2}
        direction={"row"}
        style={{ marginBottom: 22 }}
      >
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <h1
            style={{
              lineHeight: "0px",
              fontFamily: "Poppins-Black",
              fontSize: "48px",
              color: "#000000",
            }}
          >
            {"liste des sinistres".toLocaleUpperCase()}
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          style={{ display: "flex", justifyContent: "right" }}
        >
          <CodenektButton
            blue
            color={WHITECN}
            height={"2rem"}
            paddingLeft={"28px"}
            paddingRight={"28px"}
            borderRadius={"50px"}
            fontFamily={"Poppins-ExtraBold"}
            size={10}
            startIcon={<CodeNektAdd size={15} />}
            title={"Ajouter un sinistre"}
            to={LinkAjoutSinistre}
          />
        </Grid>
      </Grid>
      <div className={classes.sinistresTable}>
        <CodeNektTable
          column={column}
          data={data}
          padding={"0px 20px"}
          selection={false}
          searchFieldVariant={"outlined"}
          showTextRowsSelected={false}
          placeholder={"Rechercher un véhicule par exemple"}
        />
      </div>
    </div>
  );
};

export default Claims;
