import APIPaths from "../APIPaths";
import API from "../API";

export const getNotifications = (token, is_read) => {
  return API("POST", APIPaths.GETNOTIFS, { is_read }, token);
};

export const readNotification = (token, id) => {
  return API("POST", APIPaths.READNOTIF, { id }, token);
};

export const deleteNotification = (token, id) => {
  return API("POST", APIPaths.DELETEEVENT, { id }, token);
};
