import React from "react";
import { Paper, Divider, Button } from "@mui/material";

import { CodeNektEye } from "../../Components/CodeNektIcons";
import { TablePaperStyle } from "./Styles";
import TCOChip from "../utils/TCOChip";
import { useStylesTCO } from "./TCOStyle";

const TCOButton = () => {
  return (
    <Button
      style={{
        fontFamily: "Poppins-ExtraBold",
        color: "white",
        fontSize: "14px",
        backgroundColor: "#0898FF",
        borderRadius: "50px",
        width: "100%",
        height: 27,
        marginTop: 23,
        marginBottom: 25,
      }}
      startIcon={<CodeNektEye size={12} color={"#ffffff"} />}
      onClick={() => navigate("/vehicule")}
    >
      VOIR LE COÛT PAR VÉHICULE
    </Button>
  );
};

const TCOTableField = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 3,
      }}
    >
      <div
        style={{
          fontFamily: "Poppins-Medium",
          fontSize: "16px",
          color: "#000000",
        }}
      >
        {props.title}
      </div>
      <div>
        <TCOChip label={props.label} fontSize={30} />
      </div>
    </div>
  );
};

const TCOTable = () => {
  const classes = useStylesTCO();
  return (
    <Paper elevation={3} style={TablePaperStyle}>
      <div className={classes.typography}>TCO par mois</div>
      <Divider sx={{ backgroundColor: "#0898FF" }} />
      <div
        style={{
          marginTop: "28px",
          marginLeft: "16px",
          marginRight: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 24,
          }}
        >
          <TCOTableField title="COÛT TOTAL DU PARC" label="30 214,50 €" />
          <TCOTableField title="COÛT MOYEN PAR VÉHICULE" label="2 517,90 €" />
        </div>

        <TCOButton />
      </div>
    </Paper>
  );
};

export default TCOTable;
