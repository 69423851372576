// ---------------------------------
// Main Pages
// ---------------------------------

export const LinkSinistre = "/claims";
export const LinkVehicule = "/vehicule";
export const LinkCollaborateur = "/collaborateur";
export const LinkDashboard = "/";
export const LinkNotifications = "/notifications";
export const LinkCartes = "/cartes";
export const LinkTCO = "/tco";
export const LinkServices = "/services";
export const LinkSettings = "/settings";
export const LinkRetranscription = "/retranscription";
export const LinkSignout = "/signout";

// ---------------------------------
// Ajouter Pages
// ---------------------------------

export const LinkAjouter = "/ajouter";
export const LinkAjoutCollab = "/collaborateur/ajouter";
export const LinkAjoutVehicule = "/vehicule/ajouter";
export const LinkAjoutSinistre = "/sinistre/ajouter";
export const LinkAjoutCarte = "/carte/ajouter";

// ---------------------------------
// Voir Pages
// ---------------------------------

export const LinkVoir = "/voir";
export const LinkVoirCollab = "/collaborateur/voir";
export const LinkVoirVehicule = "/vehicule/voir";
export const LinkVoirSinistre = "/sinistre/voir";
export const LinkVoirCarte = "/carte/voir";
