import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStylesTCO = makeStyles((theme) =>
  createStyles({
    typography: {
      fontFamily: "Poppins-Black",
      fontSize: "24px",
      color: "#000000",
      margin: "21px 0px 8px 35px",
    },
    fontTCO: {},
  })
);
