const initialState = {
  read: [],
  unread: [],
  notifications: [],
};

function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_NOTIFICATIONS":
      return {
        ...state,
        notifications: [...action.data],
      };
    case "READ_NOTIFICATIONS":
      return {
        ...state,
        read: [...action.data],
      };
    case "UNREAD_NOTIFICATIONS":
      return {
        ...state,
        unread: [...action.data],
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}

export default notificationReducer;
