const initialState = {
  collaborateurs: [],
};

function collaborateursReducer(state = initialState, action) {
  switch (action.type) {
    case "LIST_COLLABORATEURS":
      return {
        ...state,
        collaborateurs: [...action.data],
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
}

export default collaborateursReducer;
