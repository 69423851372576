import React from "react";
import { useDispatch } from "react-redux";

// import Logo from "../../assets/svg/logo/logo_fondOrange.svg";
import Logo from "../../assets/png/logoPng.png";
import CodenektButton from "../../Components/CodeNektButton";
import CodeNektSidePanel from "../../Components/CodeNektSidePanel";
import { useNavigate } from "react-router-dom";

const SignoutButton = (props) => {
  return (
    <CodenektButton
      white
      onClick={props.onClick}
      title="Se déconnecter"
      width="100%"
    />
  );
};

const SignoutForm = (props) => {
  const dispatch = useDispatch();
  const Logout = () => {
    dispatch({ type: "LOGOUT" });
    window?.location?.reload();
  };

  return (
    <div
      style={{
        alignContent: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <SignoutButton onClick={Logout} />
    </div>
  );
};

const Signout = (props) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="CodeNekt__logo">
        <img src={Logo} alt="" style={{ width: "10rem" }} />
        <SignoutForm />
      </div>
    </div>
  );
};

export default Signout;
