import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import FullLogo from "../../assets/svg/logo/logo_full.svg";
import Verified from "../../assets/svg/icons/verified.svg";
import InformationProprietaire from "../../assets/svg/icons/InformationProprietaire.svg";
import { years } from "./fakeData";
import { Button } from "@mui/base";
import Logo from "../../assets/png/logoPng.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { CircularProgress, TextField } from "@mui/material";
import { getVehicleNFT } from "../../api/modules/Vehicle";
import { Typography } from "@material-ui/core";
import ModalPrestataire from "./ModalPrestataire";

const MenuYearItem = ({ year, onClick, isSelected }) => {
  const classes = useStyles();
  return (
    <div className={isSelected && classes.isMenuYearSelected} onClick={onClick}>
      {year}
    </div>
  );
};

const HistoriqueItem = ({ isVerified, historique }) => {
  const classes = useStyles();
  return (
    <div className={classes.historiqueItemContainer}>
      <div
        className={
          isVerified ? classes.iconVerification : classes.iconProprietaire
        }
      >
        {isVerified ? (
          <img src={Verified} alt="Verified" />
        ) : (
          <img src={InformationProprietaire} alt="Infos" />
        )}
      </div>
      <div className={classes.historiqueRow}>
        <div className="year">
          {new Date(historique.date * 1000).toLocaleDateString()}
        </div>
        <div className="revision">
          {historique.type === "MINT" ? "CREATION" : historique.type}
        </div>
        <div className={classes.notes}>
          <div className="notes">Note</div>
          <p>{historique?.description}</p>
        </div>
        <div>
          {historique?.document?.map((document, index) => (
            <Button key={index} className={classes.buttonDocument}>
              {document.title}
            </Button>
          ))}
        </div>
      </div>
      {/*
      <div className={classes.expendMore}>
        <ExpandMoreIcon />
      </div>
    */}
    </div>
  );
};

const BeforeRetranscription = ({ onClick, error, isLoading }) => {
  const [val, setVal] = useState("");
  const classes = useStyles();
  return (
    <div className={classes.beforeRetranscription}>
      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <div>
          <img src={Logo} alt="" style={{ width: "12rem", marginBottom: 20 }} />
          <TextField
            fullWidth
            className={classes.beforeTextfield}
            id="outlined-basic"
            label="Veuillez taper votre immatriculation pour afficher l'historique certifié du véhicule"
            variant="outlined"
            value={val}
            onChange={(event) => setVal(event.target.value)}
          />
          {error && <Typography>Le véhicule n'existe pas</Typography>}
          <Button
            className={classes.beforeButtom}
            variant="contained"
            onClick={() => onClick(val)}
          >
            Rechercher
          </Button>
        </div>
      )}
    </div>
  );
};

const Retranscription = () => {
  const classes = useStyles();
  const [isSelectedYear, setIsSelectedYear] = useState(2023);
  const [isValidate, setIsValidate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [vehicle, setVehicle] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeYear = (year) => {
    setIsSelectedYear(year);
  };

  const onClickSearch = (value) => {
    setIsLoading(true);
    getVehicleNFT(value.length > 0 ? value : "NONE").then((response) => {
      setIsLoading(false);
      if (response.ok) {
        setIsValidate(true);
        setVehicle(response.data);
      } else {
        setIsValidate(false);
        setIsError(true);
      }
    });
  };

  return (
    <div>
      {isValidate && vehicle !== undefined ? (
        <div className={classes.container}>
          <div className={classes.banner}>
            <div>
              <div>
                <img src={FullLogo} alt="Logo" />
              </div>
              <div className={classes.titleVehicule}>
                Historique du véhicule
              </div>
            </div>
          </div>
          <div className={classes.content}>
            <div className={classes.contentLeft}>
              <div className={classes.title}>
                <div className={classes.titlevehicule2}>
                  {vehicle.brand + " " + vehicle.model}
                </div>
                <div className={classes.titleNumber}>
                  {vehicle.licencePlate}
                </div>
              </div>
              <div>
                <div>
                  <div
                    className={classes.menulist}
                    style={{ marginTop: "40px" }}
                  >
                    <div>Marque</div>
                    <div>{vehicle.brand}</div>
                  </div>
                  <div className={classes.menulist}>
                    <div>Modèle</div>
                    <div>{vehicle.model}</div>
                  </div>
                  <div className={classes.menulist}>
                    <div>Immatriculation</div>
                    <div>{vehicle.licencePlate}</div>
                  </div>
                  <div className={classes.menulist}>
                    <div>Date de mise en circulation</div>
                    <div>
                      {new Date(
                        vehicle.dateFirstRegistration
                      ).toLocaleDateString()}
                    </div>
                  </div>
                  <div className={classes.menulist}>
                    <div>Numéro de série</div>
                    <div>{vehicle.chassisNumber}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.contentRight}>
              <div className={classes.containerInformation}>
                <div className={classes.information}>
                  <img src={Verified} alt="Verified" />
                  <div className={classes.textInformation}>
                    Intervention vérifiée et certifiée par Codenekt
                  </div>
                </div>
                <div className={classes.information}>
                  <img src={InformationProprietaire} alt="Infos" />
                  <div className={classes.textInformation}>
                    Information déclarée par le propriétaire
                  </div>
                </div>
              </div>
              <div className={classes.historique}>
                <div className={classes.containerHistorique}>
                  <div>Historique</div>
                  <div className={classes.containerYears}>
                    {years.map((year, index) => (
                      <MenuYearItem
                        year={year}
                        key={index}
                        onClick={() => handleChangeYear(year)}
                        isSelected={year === isSelectedYear}
                      />
                    ))}
                  </div>
                </div>
                <div className={classes.historiqueList}>
                  {vehicle.events.map((event, index) => {
                    if (
                      new Date(JSON.parse(event).date * 1000).getFullYear() ===
                      isSelectedYear
                    ) {
                      return (
                        <HistoriqueItem
                          key={index}
                          isVerified
                          historique={JSON.parse(event)}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <BeforeRetranscription
          onClick={(val) => onClickSearch(val)}
          error={isError}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default Retranscription;
