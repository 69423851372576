import React, { useEffect, useState } from "react";
import { IconButton, InputLabel, FormControl } from "@mui/material";

// Local imports
import CodenektButton from "../Components/CodeNektButton";
import CodeNektTable from "../Components/CodeNektTable";
import { FONTSEMIBIG } from "../utils/fontSize";
import { FONTBOLD } from "../utils/fonts";
import {
  CodeNektEye,
  CodeNektBell,
  CodeNektCheckCircle,
  CodeNektDelete,
  CodeNektBellFill,
} from "../Components/CodeNektIcons";
import { GREYBACK, WHITECN } from "../utils/colors";
import CodeNektSelect from "../Components/CodeNektSelect";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "../api/modules/Notifications";
import moment from "moment";

const GroupActions = (props) => {
  const handleGroupAction = (action) => {
    console.log("Selected Rows:", props.selectedRows);
    console.log("Action:", action);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="groupes-actions"> Actions groupées </InputLabel>
      <CodeNektSelect
        backgroundColor={GREYBACK}
        borderRadius={5}
        id={"groupes-actions"}
        label={"Actions groupées"}
        options={[
          { value: "lues", label: "Marquer comme lues" },
          { value: "relancer", label: "Relancer les collaborateurs" },
          { value: "supprimer", label: "Supprimer les notifications" },
        ]}
        onChange={handleGroupAction}
        value={props.selectedRows}
        width={"50%"}
      />
    </FormControl>
  );
};

function Notifications() {
  useEffect(() => {
    getNotificationsList();
  }, []);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);

  const getNotificationsList = async () => {
    try {
      const responseRead = await getNotifications(user.token, true);
      const responseNotRead = await getNotifications(user.token, false);

      if (responseRead.status === 200 || responseNotRead.status === 200) {
        const notificationsListRead = responseRead.data;
        const notificationsListNotRead = responseNotRead.data;
        const allNotifications = [
          ...notificationsListRead,
          ...notificationsListNotRead,
        ].map((notif) => {
          return { ...notif, data: JSON.parse(notif.data) };
        });
        dispatch({ type: "LIST_NOTIFICATIONS", data: allNotifications });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const notifications = useSelector(
    (state) => state.notificationReducer.notifications
  );
  const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectionChange = (rows) => {
    setSelectedRows(rows);
  };

  const column = {
    names: [
      {
        title: "Date",
        field: "Date",
        width: 100,
        render: (data) => {
          return <>{moment(data.date).format("DD-MM-YYYY")}</>;
        },
      },
      {
        title: "Nom",
        field: "data.name",
      },
      { title: "Type d'Intervention", field: "type", width: 220 },
      {
        title: "Statut",
        field: "Statut",
        width: 160,
        render: (data) => {
          return <>{data.is_read === 1 ? "Lu" : "Non lu"}</>;
        },
      },
      {
        title: "Points",
        field: "data.points",
        width: 160,
      },
      {
        title: "Marque",
        field: "",
        width: 90,
      },
      { title: "Collaborateur", field: "Collaborateur", width: 180 },
      {
        title: <GroupActions rows={selectedRows} />,
        field: "actions",
        width: 380,
        sorting: false,
        filtering: false,
        search: false,
        textAlign: "right",
        render: (rowData) => {
          return (
            <>
              <IconButton>
                <CodeNektEye size={15} />{" "}
              </IconButton>
              <IconButton>
                <CodeNektCheckCircle size={15} />{" "}
              </IconButton>
              <CodenektButton
                blue
                color={WHITECN}
                onPress={() => console.log("Button pressed")}
                width={200}
                padding={"0 0.1rem"}
                size={10}
                startIcon={<CodeNektBell size={12} />}
                title={"Relancer le collaborateur"}
              />
              <IconButton>
                {" "}
                <CodeNektDelete size={15} />{" "}
              </IconButton>
            </>
          );
        },
      },
    ],
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", textAlign: "left" }}
    >
      <h1 size={FONTSEMIBIG} style={{ fontWeight: FONTBOLD }}>
        NOTIFICATIONS
      </h1>
      <CodeNektTable
        column={column}
        data={notifications}
        onSelectionChange={handleSelectionChange}
      />
    </div>
  );
}

export default Notifications;
