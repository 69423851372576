import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: "100%",
    },
    banner: {
      "& >div": {
        display: "flex",
        alignItems: "end",
        width: "calc(60% + 300px)",
        margin: "auto",
        gap: "30px",
      },
      height: 174,
      display: "flex",
      background:
        "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,111,8,1) 100%)",
    },
    titleVehicule: {
      fontFamily: "Poppins-Light",
      fontSize: 30,
      lineHeight: "40px",
      color: "#312B27",
      textTransform: "uppercase",
    },
    titlevehicule2: {
      fontFamily: "Poppins-Black",
      fontSize: 24,
      color: "#312B27",
    },
    titleNumber: {
      fontFamily: "Poppins-Black",
      fontSize: 24,
      color: "#FF6F08",
      lineHeight: "15px",
    },
    content: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 30,
      gap: "20px",
    },
    contentLeft: {
      width: 300,
    },
    contentRight: {
      width: "60%",
    },
    title: {},
    subtitle: {
      margin: "40px 0px 50px",
      "& div:first-child": {
        fontFamily: "Poppins-Regular",
        fontSize: 12,
        color: "#ADADAD",
      },
      "& div:last-child": {
        borderBottom: "1px solid #ADADAD",
      },
    },
    menulist: {
      borderBottom: "1px solid #ADADAD",
      display: "flex",
      justifyContent: "space-between",
      "& div:first-child": {
        fontFamily: "Poppins-Regular",
        fontSize: 12,
        color: "#ADADAD",
      },
      "& div:last-child": {
        fontFamily: "Poppins-Regular",
        fontSize: 12,
        color: "#312B27",
      },
    },
    containerInformation: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      justifyContent: "flex-end",
      margin: "0px 20px 7px",
    },
    information: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      alignItems: "center",
    },
    textInformation: {
      fontFamily: "Poppins-Italic",
      fontSize: "10px",
      textWrap: "nowrap",
    },
    historique: {
      border: "1px solid #707070",
      borderRadius: 20,
      padding: 20,
    },

    containerHistorique: {
      display: "flex",
      gap: "50px",
      marginBottom: 40,
      padding: "0px 16px",
    },
    containerYears: {
      cursor: "pointer",
      display: "flex",
      gap: "20px",
      color: "#CECBCB",
      fontFamily: "Poppins-Bold",
      fontSize: 15,
    },
    isMenuYearSelected: {
      color: "#FF6F04",
    },
    historiqueList: {
      height: "calc(100vh - 400px)",
      padding: "0px 20px",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: 6,
        height: 8,
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 6,
        background: "#FF6F08",
        boxShadow: "inset 0 0 6px rgb(0 0 0 / 50%)",
      },
      "& >div:last-child": {
        border: 0,
      },
    },
    historiqueItemContainer: {
      position: "relative",
      borderLeft: "1px solid #FF6F04",
      padding: "0px 20px 30px",
    },
    iconVerification: {
      position: "absolute",
      top: 0,
      left: -12,
    },
    iconProprietaire: { position: "absolute", top: 0, left: -7 },
    historiqueRow: {
      display: "flex",
      gap: 30,
      height: "70px",
      overflow: "hidden",
      "& .year": {
        fontFamily: "Poppins-Regular",
        fontSize: 14,
      },
      "& .revision": {
        fontFamily: "Poppins-Bold",
        fontSize: 14,
        flex: "none",
        width: "80px",
      },
    },
    notes: {
      "& .notes": {
        fontFamily: "Poppins-Bold",
        fontSize: 12,
      },
      "& p": {
        margin: 0,
        padding: 0,
        fontFamily: "Poppins-Regular",
        fontSize: 12,
      },
    },
    buttonDocument: {
      textWrap: "nowrap",
      backgroundColor: "#E2E2E2",
      padding: "1px 22px",
      borderRadius: "50px",
      fontSize: "12px",
      fontFamily: "Poppins-Bold",
      color: "#312B27",
      border: 0,
      textTransform: "uppercase",
    },
    prestataire: {
      "& .prestataireName": {
        fontFamily: "Poppins-Bold",
        fontSize: 12,
        textDecoration: "underline",
        textWrap: "nowrap",
      },
      "& .ville": {
        fontFamily: "Poppins-Regular",
        fontSize: 12,
      },
    },
    expendMore: {
      backgroundColor: "#FF6F041A",
      textAlign: "center",
      height: "24px",
      cursor: "pointer",
      marginTop: 5,
      borderBottom: "1px solid #ADADAD",
    },

    beforeRetranscription: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#0898FF",
      "& >div": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "650px",
        gap: "20px",
      },
    },
    beforeTextfield: {
      "& .MuiInputBase-input": {
        fontFamily: "Poppins-Regular",
      },
      "& .MuiInputBase-root": {
        borderRadius: 21,
      },
      "& .MuiOutlinedInput-notchedOutline": {},
    },
    beforeButtom: {
      width: "100%",
      height: "31px",
      borderRadius: "50px",
      color: "#0898FF",
      fontFamily: "Poppins-Bold",
      fontSize: "14px",
      margin: "0",
      backgroundColor: "white",
      border: 0,
    },
  })
);
