import React from "react";
import { Paper, Divider, Button } from "@mui/material";
import { BLUECN, ORANGEDARK, WHITECN, GREENCN } from "../../utils/colors";
import { CodeNektEye } from "../../Components/CodeNektIcons";
import { TablePaperStyle } from "./Styles";
import { useStylesVehicule } from "./VehiculeStyle";
import { useNavigate } from "react-router-dom";

const VehicleChip = (props) => {
  return (
    <div
      style={{
        backgroundColor: `${props.backgroundColor}`,
        width: 58,
        height: 25,
        borderRadius: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p
        style={{
          fontFamily: "Poppins-Medium",
          fontSize: 18,
          color: props.color,
          margin: 0,
        }}
      >
        {props.label}
      </p>
    </div>
  );
};

const VehicleTypography = (props) => {
  return (
    <div>
      <p
        style={{
          fontSize: "18px",
          fontFamily: "Poppins-Medium",
        }}
      >
        {props.title}
      </p>
    </div>
  );
};

const VehiclesTable = () => {
  const classes = useStylesVehicule();
  const navigate = useNavigate();
  return (
    <Paper elevation={3} style={TablePaperStyle}>
      <div>
        <div className={classes.typography}>VÉHICULES</div>
        <Divider sx={{ backgroundColor: "#0898FF" }} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className={classes.chipNumber}>212</div>
        </div>
        <div style={{ margin: "0px 11px 0px 11px" }}>
          <div className={classes.divider}>
            <VehicleTypography title="EN COMMANDE" />
            <VehicleChip label="30" backgroundColor={BLUECN} color={WHITECN} />
          </div>
          <div className={classes.divider}>
            <VehicleTypography title="AFFECTÉS" />
            <VehicleChip label="2" backgroundColor={GREENCN} color={WHITECN} />
          </div>
          <div className={classes.divider}>
            <VehicleTypography title="LIBRES" />
            <VehicleChip
              label="1"
              backgroundColor={ORANGEDARK}
              color={WHITECN}
            />
          </div>
          <div className={classes.divider}>
            <VehicleTypography title="EN ATTENTE DE VALIDATION" />
            <VehicleChip
              label="1"
              backgroundColor={ORANGEDARK}
              color={WHITECN}
            />
          </div>
          <Button
            style={{
              fontFamily: "Poppins-ExtraBold",
              color: "white",
              fontSize: "14px",
              backgroundColor: "#0898FF",
              borderRadius: "50px",
              width: "100%",
              height: 27,
              marginTop: 23,
              marginBottom: 30,
            }}
            startIcon={<CodeNektEye size={12} color={"#ffffff"} />}
            onClick={() => navigate("/vehicule")}
          >
            VOIR TOUS LES VÉHICULES
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default VehiclesTable;
