import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import NotificationTable from "./NotificationTable";
import VehiclesTable from "./VehiclesTable";
import SinistreTable from "./SinistresTable";
import TCOTable from "./TCOTable";
import HeaderButtons from "./HeaderButtons";
import { useStyles } from "./NotificationStyle";
import { useTranslation } from "react-i18next";

const Dashboard = (props) => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.userReducer);
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "45px 116px 34px 79px",
      }}
    >
      <div
        style={{ fontFamily: "Poppins-Bold", fontSize: 48, color: "#000000" }}
      >
        {t("dashboard.dashboard", "DASHBOARD")} &emsp;
      </div>
      {/*
    <span style={{ fontSize: "1.4rem", color: "#000000" }}>
        Bienvenue {user.firstname}
      </span>
    */}

      <HeaderButtons />
      <Grid container spacing={1.5}>
        <Grid item xs={12} sm={5} md={4}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            spacing={1.5}
            direction="column"
          >
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ width: "100%" }}>
                <VehiclesTable />
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <div style={{ width: "100%" }}>
                <TCOTable />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={7} md={8}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            spacing={1.5}
            direction="column"
          >
            <Grid className={classes.tableTd} item xs={12} sm={8} md={8}>
              <NotificationTable />
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <SinistreTable />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
