import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid, IconButton } from "@mui/material";

import CodeNektTable from "../../Components/CodeNektTable";
import { WHITECN } from "../../utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";

// import MUI icons
import {
  CodeNektAdd,
  CodeNektDelete,
  CodeNektEye,
} from "../../Components/CodeNektIcons";
import CodenektButton from "../../Components/CodeNektButton";
import {
  LinkAjoutVehicule,
  LinkVoirVehicule,
} from "../../Components/CodeNektPageLinks";
import { getVehicles } from "../../api/modules/Vehicle";

const column = {
  names: [
    { title: "Immat".toLocaleUpperCase(), field: "number", width: 100 },
    {
      title: "Marque".toLocaleUpperCase(),
      field: "brand_model.brand.name",
      width: 100,
    },
    {
      title: "Modele".toLocaleUpperCase(),
      field: "brand_model.name",
      width: 180,
    },
    {
      title: "Cat".toLocaleUpperCase(),
      field: "brand_model.brand.type",
      width: 60,
    },
    {
      title: "Collaborateur".toLocaleUpperCase(),
      field: "Collaborateur",
      width: 180,
    },
    {
      title: "Entrée".toLocaleUpperCase(),
      field: "insurance.start_date",
      width: 100,
    },
    {
      title: "Sortie".toLocaleUpperCase(),
      field: "insurance.end_date",
      width: 100,
    },
    { title: "Statut".toLocaleUpperCase(), field: "statut", width: 180 },
    {
      title: "Actions".toLocaleUpperCase(),
      field: "actions",
      width: 80,
      sorting: false,
      filtering: false,
      search: false,
      render: (rowData) => {
        return (
          <div>
            <Link to={LinkVoirVehicule}>
              <IconButton>
                <CodeNektEye size={15} />
              </IconButton>
            </Link>
            <IconButton>
              <CodeNektDelete size={15} />
            </IconButton>
          </div>
        );
      },
    },
  ],
};

const Vehicles = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state) => state.userReducer);
  const getCars = async () => {
    try {
      const response = await getVehicles(user.token);
      if (response.status === 200) {
        const vehicules = response.data;
        dispatch({ type: "GET_VEHICULES", data: vehicules });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCars();
  }, []);
  const vehicules = useSelector((state) => state.brandsReducer.vehicules);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        margin: "32px 60px 60px 60px",
      }}
    >
      <Grid
        container
        spacing={2}
        direction={"row"}
        style={{ marginBottom: 22 }}
      >
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <h1
            style={{
              fontFamily: "Poppins-Black",
              fontSize: "48px",
              lineHeight: "0px",
            }}
          >
            {"liste des véhicules".toLocaleUpperCase()}
          </h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          style={{ display: "flex", justifyContent: "right" }}
        >
          <CodenektButton
            blue
            color={WHITECN}
            height={"2rem"}
            paddingLeft={"28px"}
            paddingRight={"28px"}
            borderRadius={"50px"}
            fontFamily={"Poppins-ExtraBold"}
            size={10}
            startIcon={<CodeNektAdd size={15} />}
            title={"Ajouter un véhicule"}
            to={LinkAjoutVehicule}
          />
        </Grid>
      </Grid>
      <div className={classes.vehiculesTable}>
        <CodeNektTable
          key={"vehicule"}
          column={column}
          data={vehicules}
          searchFieldVariant={"outlined"}
          showTextRowsSelected={false}
          placeholder={"Rechercher un marque par exemple"}
        />
      </div>
    </div>
  );
};

export default Vehicles;
