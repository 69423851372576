import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
  createStyles({
    sinistresTable: {
      "& .MuiTextField-root": {
        width: "26%",
        backgroundColor: "#F2F2F2",
        borderRadius: "50px",
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
        "& .MuiOutlinedInput-root": {
          height: "31px",
          fontFamily: "Poppins-Regular",
          fontSize: "10px",
        },
        "& .MuiIconButton-root": {
          display: "none",
        },
        "& .MuiSvgIcon-fontSizeSmall": {
          position: "absolute",
          right: "12px",
        },
        "& .MuiOutlinedInput-input": {
          position: "absolute",
        },
      },
      "& .MuiTableCell-head": {
        borderBottom: "1px solid #0898ff",
      },
      "& .MuiTableHead-root .MuiTableCell-root .MuiTableSortLabel-root, & .MuiTableHead-root .MuiTableCell-root > div":
        {
          fontFamily: "Poppins-Bold",
          fontSize: "14px",
          color: "#000000",
        },
      "& .MuiTableBody-root": {
        fontFamily: "Poppins-Regular",
        fontSize: "12px",
        textWrap: "nowrap",
        "& .MuiTableCell-body": {
          borderBottom: "1px solid #0898ff",
        },
      },
      "& .MuiToolbar-gutters": {
        padding: 0,
      },
      "& .MuiPaper-root .MuiPaper-elevation .MuiTableFooter-root .MuiTableRow-footer":
        {
          justifyContent: "center",
        },
      //   "& .MuiPaper-root .MuiTableFooter-root .MuiTableRow-footer .MuiButtonBase-root[aria-label='Previous Page'], & .MuiPaper-root .MuiTableFooter-root .MuiTableRow-footer .MuiButtonBase-root[aria-label='Next Page']":
      //     {
      //       backgroundColor: "#0898FF",
      //       color: "white",
      //       padding: 8,
      //     },
      //   "& .MuiPaper-root .MuiTableFooter-root .MuiTableRow-footer .Mui-disabled":
      //     {
      //       backgroundColor: "white",
      //       //   borderRight: "2px solid #b3b3b3",
      //       //   borderRadius: 0,
      //     },
    },
  })
);
